import { Api } from "../axios-config";

export type GetSinisterFollowupNotesResponse = {
	id: string;
	userId: string;
	userName: string;
	createdAt: Date;
	note: string;
};

export type SinisterDataType = {
	id: string;
	email: string;
};

const getSinisterFollowupNotes = async (sinisterId: string): Promise<GetSinisterFollowupNotesResponse[]> => {
	try {
		const { data } = await Api().get(`/sinister-followup/${sinisterId}/notes`);

		if (data) return data;

		throw new Promise((_, reject) => reject(new Error("Erro ao carregar os followups.")));
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

const addSinisterFollowupNote = async (sinisterId: string, formattedNote: string): Promise<void> => {
	try {
		const { data } = await Api().post(`/sinister-followup/notes`, {
			sinisterId,
			formattedNote,
		});

		if (data) return data;

		throw new Promise((_, reject) => reject(new Error("Erro ao adicionar followup.")));
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

const getSinisterFollowupContacts = async (sinisterId: string): Promise<SinisterDataType[]> => {
	try {
		const { data } = await Api().get(`/sinister-followup/${sinisterId}/contacts`);
		if (data) return data;

		throw new Promise((_, reject) => reject(new Error("Erro ao carregar emails de contato!")));
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

const addSinisterFollowupContact = async (sinisterId: string, email: string): Promise<void> => {
	try {
		const { data } = await Api().post(`/sinister-followup/contacts`, {
			sinisterId,
			email,
		});

		if (data) return data;

		throw new Promise((_, reject) => reject(new Error("Erro ao adicionar email de contato!")));
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

const removeSinisterFollowupContact = async (contactId: string): Promise<void> => {
	try {
		await Api().delete(`/sinister-followup/contacts/${contactId}`);
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

export const SinisterFollowUpService = {
	getSinisterFollowupNotes,
	addSinisterFollowupNote,
	getSinisterFollowupContacts,
	addSinisterFollowupContact,
	removeSinisterFollowupContact,
};
