import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { ModalLogsProposal } from "../Modals/ModalLogsProposal";
import { ModalLogSerasa } from "../Modals/ModalLogsSerasa";
import { ModalLogsUpdateProposal } from "../Modals/ModalLogsUpdateProposal";

interface Props {
	loggedUser?: { role: string };
	isSuperAdmin: boolean;
	rows: any;
	smDown: boolean;
	isLoad?: boolean;
}

const ProposalLogs: React.FC<Props> = ({ loggedUser, isSuperAdmin, smDown, rows }) => {
	const [openModalLogsSerasa, setOpenModalLogsSerasa] = useState(false);
	const [openModalLogs, setOpenModalLogs] = useState(false);
	const [openModalLogsUpdate, setOpenModalLogsUpdate] = useState(false);

	const handleCloseModalLogsSerasa = () => setOpenModalLogsSerasa(false);
	const handleCloseModalLogs = () => setOpenModalLogs(false);
	const handleCloseModalLogsUpdate = () => setOpenModalLogsUpdate(false);
	const handleOpenModalLogs = () => setOpenModalLogs(true);
	const handleOpenModalLogsSerasa = () => setOpenModalLogsSerasa(true);
	const handleOpenModalLogsUpdate = () => setOpenModalLogsUpdate(true);

	return (
		<>
			{loggedUser?.role === "admin" && isSuperAdmin && (
				<Grid item xs={12} md={12}>
					<Grid container component={Paper} padding={2} ml={1} display="flex" flexDirection={smDown ? "column" : "row"}>
						<Typography variant="h6" color="secondary">
							Logs da Garantia
						</Typography>
						<Grid item xs={12} container spacing={1} gap={1} mt={2} ml={2} mb={1}>
							<LogItem title="Log da Garantia" onClick={() => handleOpenModalLogs()} />
							<LogItem
								title="Log do Serasa"
								onClick={() => handleOpenModalLogsSerasa()}
								disabled={!rows?.sendSerasa}
								additionalText={rows?.sendSerasa == "send_serasa" ? "Garantia consultou Serasa" : "Não enviado ao Serasa"}
							/>
							<LogItem title="Alteração de Garantia" onClick={() => handleOpenModalLogsUpdate()} />
						</Grid>
					</Grid>
				</Grid>
			)}

			{/* Modal de logs do Serasa */}
			{loggedUser?.role === "admin" && <ModalLogSerasa isOpenModal={openModalLogsSerasa} isClose={handleCloseModalLogsSerasa} rows={rows} />}

			{/* Modal de logs */}
			{loggedUser?.role === "admin" && <ModalLogsProposal isClose={handleCloseModalLogs} isOpenModal={openModalLogs} rows={rows} />}

			{/* Modal de logs de atualização */}
			{loggedUser?.role === "admin" && <ModalLogsUpdateProposal isClose={handleCloseModalLogsUpdate} isOpenModal={openModalLogsUpdate} rows={rows} />}
		</>
	);
};

const LogItem: React.FC<{ title: string; onClick: () => void; disabled?: boolean; additionalText?: string }> = ({
	title,
	onClick,
	disabled = false,
	additionalText,
}) => (
	<Box
		display="flex"
		sx={{
			boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
			padding: 2,
			alignItems: "center",
		}}
		width="100%"
		gap={2}
	>
		<Typography variant="h6" sx={{ fontWeight: 400 }}>
			{title}
		</Typography>
		<Button color="primary" variant="outlined" disableElevation startIcon={<RemoveRedEyeOutlinedIcon />} onClick={onClick} disabled={disabled}>
			Visualizar
		</Button>
		{additionalText && (
			<Typography variant="body1" color="text.secondary">
				{additionalText}
			</Typography>
		)}
	</Box>
);

export default ProposalLogs;
